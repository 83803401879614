import _ from 'lodash'
import { parseInstance } from '../../utils/utils'
import * as axios from 'axios'
import { CustomField, CustomFieldResponse } from '../../constants/field-types'
import { FORMS_APP_DEF_ID } from '../../constants'
import {
  PremiumRestriction as DomainPremiumRestriction,
  ConvertEmailRequest,
  ConvertEmailResponse,
} from '../../types/domain-types'
import { PremiumRestriction, Feature } from '../../constants/premium'
import { FormPlugin } from '@wix/forms-common'
import { ASCEND_PLAN, MAP_PRODUCT_ID_TO_ASCEND_PLAN } from './constants/ascend'
import { WixContactsWebapp } from '@wix/ambassador-wix-contacts-webapp/http'
import { MigrateFieldsRequest, MigrateFieldsResponse, ExternalFieldMapping } from '@wix/ambassador-wix-contacts-webapp/types'
import { ActionTriggersServer, Rule, Condition } from '@wix/ambassador-action-triggers-server/http'
import {
  FORMS_SERVER,
  PLATFORMIZED_PAYMENTS_URL,
  PREMIUM_STORE_URL,
  FEATURES_MANAGER_URL,
  FORMS_SERVERLESS,
} from './constants/external-endpoints'
import Experiments from '@wix/wix-experiments'

export const Method = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
}

export const FEATURES = {
  CUSTOM_STEPS: 'custom_steps',
  SUBMISSIONS_PER_MONTH: 'submissions_per_month',
  CUSTOM_FIELDS: 'custom_fields',
  CUSTOMIZABLE_FORMS: 'customizable_forms',
  ACCEPT_PAYMENTS: 'accept_payments_on_form',
  UPLOAD_FIELD: 'file_upload_field',
  DOWNLOAD_FILE: 'file_downloads',
  SIGNATURE_FIELD: 'signature_field',
  EMAILS: 'max_emails_on_form',
  RULES: 'forms_conditional_forms',
}

const ASCEND_PRODUCT_ID = '73988963-5f5f-4f61-b6a1-fd004df31b00'
const callerId = { 'X-Wix-Client-Artifact-Id': 'wix-form-builder' }
const CONTACT_FORM_ACTIVITY = 'contact/contact-form'
const WIX_FORMS_ACTIVITY = 'form/form'

const createSpecificFormsCondition = (formIds: string[]): Condition => ({
  allCondition: {
    conditions: [
      {
        equalsToEitherCondition: {
          key: 'form-id',
          values: formIds,
        },
      },
    ],
  },
})

const getService = (appInstance) => ({
  contacts: () => {
    const contactsServices = WixContactsWebapp('/wix-contacts-webapp')
    return {
      labels: contactsServices.ContactsLabelsService()({ Authorization: appInstance, ...callerId }),
      schema: contactsServices.ContactsSchemaService()({ Authorization: appInstance, ...callerId }),
    }
  },
  actionTriggers: () => {
    const url = '/_api/action-triggers-server'
    return {
      triggers: ActionTriggersServer(url).ActionTriggersIntegratorService()({
        Authorization: appInstance,
        ...callerId,
      }),
      backoffice: ActionTriggersServer(url).AutomationsBackofficeService()({
        Authorization: appInstance,
        ...callerId,
      }),
    }
  },
})

export interface ContactsLabel {
  id: string
  type: string
  name: string
}

export default class RemoteApi {
  private boundEditorSDK: BoundEditorSDK
  private experiments: Experiments
  private ravenInstance

  constructor({ boundEditorSDK, experiments, ravenInstance }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
  }

  public async getFormsRules() {
    const hasConditions = (rule) => {
      const conditions = _.get(rule, 'trigger.condition.allCondition.conditions')
      return conditions && conditions.length
    }
    const appInstance = await this.boundEditorSDK.info.getAppInstance()

    const res = await getService(appInstance)
      .actionTriggers()
      .triggers.searchRules({ eventType: [CONTACT_FORM_ACTIVITY, WIX_FORMS_ACTIVITY] })

    const rules = res.rules
    const contactFormRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === CONTACT_FORM_ACTIVITY,
    )
    const wixFormsRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === WIX_FORMS_ACTIVITY,
    )
    const wixFormsAllForms = wixFormsRules.filter((rule) => !hasConditions(rule))
    const wixFormsSpecificForms = wixFormsRules.filter((rule) => hasConditions(rule))
    return { contactFormRules, wixFormsAllForms, wixFormsSpecificForms }
  }

  public async migrateRule(ruleId: string, formIds?: string[], schema?: string) {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .actionTriggers()
      .backoffice.migrateForms({ formIds, ruleId, schema })
  }

  public getEmailsById(
    emailIds = [],
  ): Promise<
    {
      email: string
      emailId: string
    }[]
  > {
    return this._platformizedRequest({
      url: FORMS_SERVER.URL,
      endpoint: FORMS_SERVER.ENDPOINT.EMAILS.GET,
      method: Method.POST,
      data: { emailIds: _.compact(emailIds) },
    }).then((data) =>
      emailIds.map((emailId) => {
        const emailFromResponse = _.find(data.emails, { emailId })
        return emailFromResponse || { emailId, email: '' }
      }),
    )
  }

  public getSiteUsersData(): Promise<SiteUserData[]> {
    return this.experiments.enabled('specs.crm.FormsEditorNewSiteUsersEndpoint')
      ? this._platformizedRequest({
          url: FORMS_SERVER.URL,
          endpoint: FORMS_SERVER.ENDPOINT.EMAILS.SITE_USERS,
          method: Method.GET,
        }).then((res) => res.emails)
      : this._platformizedRequest({
          url: FORMS_SERVERLESS.URL,
          endpoint: FORMS_SERVERLESS.ENDPOINT.USERS,
          method: Method.GET,
        })
  }

  public insertEmail(email) {
    return this._platformizedRequest({
      url: FORMS_SERVER.URL,
      endpoint: FORMS_SERVER.ENDPOINT.EMAILS.ADD,
      method: Method.POST,
      data: { email },
    })
  }

  public async createTag(tagName) {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.create({ details: { name: tagName } })
      .then((res) => res.label)
  }

  public async updateTag(tagId, newName) {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.update({ id: tagId, details: { name: newName } })
      .then((res) => res.label)
  }

  public async getLabels(): Promise<ContactsLabel[]> {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.list({})
      .then((data) => {
        return _.map(data.labels, (label) => ({
          id: label.id,
          type: label.type,
          name: label.details.name,
        }))
      })
  }

  public async getCustomFields(): Promise<CustomFieldResponse[]> {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.listFields({})
      .then(({ fields }) =>
        fields
          .filter((field) => field.fieldType === 'USER_DEFINED')
          .map<CustomFieldResponse>((field) => ({
            id: field.id,
            name: field.details.name,
            // TODO: use DataType instead of customFields
            fieldType: field.details.dataType as any,
          })),
      )
      .catch(() => [])
  }

  public async migrateFields(request:MigrateFieldsRequest): Promise<ExternalFieldMapping[]> {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.migrateFields(request)
      .then((res: MigrateFieldsResponse) => {
        return res.migrated
      })
      .catch(() => [])
  }

  public async createCustomField(field: CustomField) {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.createField({ details: { name: field.name, dataType: field.fieldType as any } })
      .then((res) => res.field)
  }

  public async updateCustomFieldName({
    id,
    newName,
  }: {
    id: string
    newName: string
  }): Promise<void> {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    await getService(appInstance)
      .contacts()
      .schema.updateField({
        id,
        details: {
          name: newName,
        },
      })
  }

  public publishSite(data) {
    return this._platformizedRequest({
      url: FORMS_SERVER.URL,
      endpoint: FORMS_SERVER.ENDPOINT.PUBLISH_SITE,
      method: Method.POST,
      data,
    })
  }

  public addContactFormMapping(data) {
    return this._platformizedRequest({
      url: FORMS_SERVER.URL,
      endpoint: FORMS_SERVER.ENDPOINT.ADD_FORM_ID,
      method: Method.POST,
      data,
    })
  }

  public editDraft(form) {
    return this._platformizedRequest({
      url: FORMS_SERVER.URL,
      endpoint: FORMS_SERVER.ENDPOINT.EDIT_DRAFT,
      method: Method.POST,
      data: { form: form },
    }).catch(() => null)
  }

  public async getPremiumRestrictions(): Promise<{
    restrictions: PremiumRestriction
    currentAscendPlan: string
  }> {
    if (this.experiments.enabled('specs.crm.FormsEditorFetchServerlessRestrictions')) {
      return this._platformizedRequest({
        url: FORMS_SERVERLESS.URL,
        endpoint: FORMS_SERVERLESS.ENDPOINT.RESTRICTIONS,
        method: Method.GET,
      })
    }

    const { restrictions: oldRestrictions } = await this._getOldRestrictions()
    const { isTopPremium, ascendPlan } = await this.getCurrentAscendPlan()
    const mergedRestrictions = await this._mergeFeaturesWithRestrictions(oldRestrictions, {
      isTopPremium,
    })

    return { restrictions: mergedRestrictions, currentAscendPlan: ascendPlan }
  }

  private async _mergeFeaturesWithRestrictions(restrictions: PremiumRestriction, { isTopPremium }) {
    const mergedRestrictions: PremiumRestriction = _.merge({}, restrictions)

    if (isTopPremium) {
      mergedRestrictions.isTopPremium = isTopPremium
    }

    const features = await this._getFeatures()
    const isEligibleFeature = (feature: Feature, name) =>
      feature.uniqueName === name && feature.id !== 'DEFAULT'

    const convertRestriction = (limit, threshold) =>
      limit === -1 ? { limit, threshold: null } : { limit, threshold }

    _.forEach(features, (feature) => {
      const limit = _.get(feature, 'quotaFeature.limit', -1)

      if (isEligibleFeature(feature, FEATURES.CUSTOM_STEPS)) {
        mergedRestrictions.steps = convertRestriction(limit, feature.quotaFeature.limit - 1)
      }

      if (isEligibleFeature(feature, FEATURES.SUBMISSIONS_PER_MONTH)) {
        mergedRestrictions.submissions = convertRestriction(limit, feature.quotaFeature.limit * 0.7)
      }

      if (isEligibleFeature(feature, FEATURES.CUSTOM_FIELDS)) {
        mergedRestrictions.fields = convertRestriction(limit, feature.quotaFeature.limit * 0.7)
      }

      if (isEligibleFeature(feature, FEATURES.CUSTOMIZABLE_FORMS)) {
        mergedRestrictions.forms = convertRestriction(limit, feature.quotaFeature.limit - 2)
      }

      if (isEligibleFeature(feature, FEATURES.RULES)) {
        mergedRestrictions.rules = convertRestriction(limit, feature.quotaFeature.limit - 1)
      }

      if (isEligibleFeature(feature, FEATURES.ACCEPT_PAYMENTS)) {
        mergedRestrictions.allowedPlugins[FormPlugin.PAYMENT_FORM] = true
      }

      if (isEligibleFeature(feature, FEATURES.UPLOAD_FIELD)) {
        mergedRestrictions.allowedFields.uploadButton = true
        mergedRestrictions.allowedFields.generalUploadButton = true
      }

      if (isEligibleFeature(feature, FEATURES.DOWNLOAD_FILE)) {
        mergedRestrictions.allowedRedirections.downloadFile = true
      }

      if (isEligibleFeature(feature, FEATURES.SIGNATURE_FIELD)) {
        mergedRestrictions.allowedFields.generalSignature = true
      }

      if (isEligibleFeature(feature, FEATURES.EMAILS)) {
        mergedRestrictions.emails = convertRestriction(limit, limit)
      }
    })

    return mergedRestrictions
  }

  public async getCurrentAscendPlan(): Promise<{ ascendPlan: string; isTopPremium: boolean }> {
    const msid = await this.boundEditorSDK.info.getMetaSiteId()
    const endpoint = `offering/${ASCEND_PRODUCT_ID}?msid=${msid}`
    let currentSubscriptionInfo

    try {
      const request = await this._platformizedRequest({
        url: PREMIUM_STORE_URL,
        endpoint,
        method: Method.GET,
      })
      currentSubscriptionInfo = _.get(request, 'currentSubscriptionInfo')
    } catch {
      currentSubscriptionInfo = {}
    }

    const productId = _.get(currentSubscriptionInfo, 'productId')

    const currentPlan = MAP_PRODUCT_ID_TO_ASCEND_PLAN[productId] || ASCEND_PLAN.FREE

    return { ascendPlan: currentPlan, isTopPremium: currentPlan === ASCEND_PLAN.UNLIMITED }
  }

  public async getConnectedPayments() {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const instanceId = parseInstance(appInstance).instanceId
    const endpoint = `accounts/${FORMS_APP_DEF_ID}:${instanceId}/payment-methods`

    return this._platformizedRequest({
      url: PLATFORMIZED_PAYMENTS_URL,
      endpoint,
      method: Method.GET,
    })
  }

  private _getOldRestrictions = () =>
    this._platformizedRequest({
      url: FORMS_SERVER.URL,
      endpoint: FORMS_SERVER.ENDPOINT.RESTRICTIONS,
      method: Method.GET,
    }).then((restrictions: DomainPremiumRestriction): { restrictions: PremiumRestriction } => {
      const convertRestriction = (restriction) =>
        _.get(restriction, 'unlimited')
          ? { limit: -1, threshold: null }
          : _.get(restriction, 'limited')
      const allowedFeatures = <any>restrictions.allowedFeatures
      return {
        restrictions: {
          steps: convertRestriction(restrictions.steps),
          forms: convertRestriction(restrictions.forms),
          rules: { limit: 3, threshold: 2 },
          submissions: convertRestriction(restrictions.submissions),
          fields: convertRestriction(restrictions.fields),
          isTopPremium: restrictions.isTopPremium,
          allowedFields: {
            generalUploadButton: _.includes(allowedFeatures, 'GENERAL_UPLOAD_BUTTON'),
            generalSignature: _.includes(allowedFeatures, 'SIGNATURE_FIELD'),
          },
          allowedRedirections: {
            downloadFile: _.includes(allowedFeatures, 'DOWNLOAD_FILE'),
          },
          allowedPlugins: {
            [FormPlugin.PAYMENT_FORM]: _.includes(allowedFeatures, 'PAYMENT_FORM'),
          },
          emails: convertRestriction(restrictions.emails),
        },
      }
    })

  private _getFeatures = async (): Promise<Feature[]> => {
    const supportedFeatures = _.chain(FEATURES)
      .values()
      .map((value) => `uniqueNames=${value}`)
      .join('&')
      .value()
    const endpoint = `bulk-features?${supportedFeatures}`
    let features

    try {
      const request = await this._platformizedRequest({
        url: FEATURES_MANAGER_URL,
        endpoint,
        method: Method.GET,
      })
      features = _.get(request, 'features')
    } catch {
      features = []
    }

    return features
  }

  public convertContactFormEmails(
    convertContactFormEmailsRequest: ConvertEmailRequest,
  ): Promise<ConvertEmailResponse> {
    return this._platformizedRequest({
      url: FORMS_SERVER.URL,
      endpoint: FORMS_SERVER.ENDPOINT.CONVERT_CONTACT_FORM_EMAIL,
      method: Method.POST,
      data: convertContactFormEmailsRequest,
    })
  }

  private async _platformizedRequest({
    url,
    endpoint,
    method,
    data = undefined,
  }: {
    url: string
    endpoint: string
    method: string
    data?: any
  }) {
    try {
      const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())

      if (isTemplate) {
        return Promise.resolve()
      }

      const appInstance = await this.boundEditorSDK.info.getAppInstance()

      const response = await axios.request({
        headers: { Authorization: appInstance, ...callerId },
        method,
        url: `${url}/${endpoint}`,
        data,
      })

      return _.get(response, 'data')
    } catch (err) {
      this.ravenInstance.setExtraContext({ url, endpoint, method, data })
      throw err
    }
  }
}
